import * as React from "react";
import { useQueryWithStore } from "react-admin";
import useUsers from "../hooks/useUsers";

const TrafficSummary = () => {
  const aMonthAgo = new Date();
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);
  aMonthAgo.setHours(0);
  aMonthAgo.setMinutes(0);
  aMonthAgo.setSeconds(0);
  aMonthAgo.setMilliseconds(0);

  const access_token =
    JSON.parse(localStorage.getItem("auth")) &&
    JSON.parse(localStorage.getItem("auth")).token;
  if (!access_token) {
    return <></>;
  }

  const { data: reviewingPosts } = useQueryWithStore({
    type: "getList",
    resource: "posts",
    payload: {
      filter: {
        status: "REVIEWING",
      },
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  const { users: premiumUsers, total } = useUsers({
    filter: {
      is_premium: true,
      role: "USER",
      is_deleted: false,
    },
    sort: { field: "last_active_at", order: "DESC" },
    pagination: { page: 1, perPage: 100 },
  });

  const { data: reportedPosts } = useQueryWithStore({
    type: "getList",
    resource: "post-reports",
    payload: {
      filter: {
        edited: false,
      },
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  const getDaysBefore = (date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days;
  };

  return (
    <>
      <div>
        <div>Post REVIEWING: {reviewingPosts?.length}</div>

        <div>Post Report: {reportedPosts?.length}</div>

        {/* <ul>
          <li>
            <b
              style={{
                display: "inline-block",
                marginTop: 1,
                textAlign: "center",
                background: "lightblue",
                width: 80 * 4,
              }}
            >
              Days Before
            </b>
            <b
              style={{
                display: "inline-block",
                marginTop: 1,
                textAlign: "center",
                background: "lightgrey",
                width: 200,
              }}
            >
              Email
            </b>
          </li>
          <li>
            <b
              style={{
                display: "inline-block",
                marginTop: 1,
                background: "lightgrey",
                width: 80,
              }}
            >
              Sub
            </b>
            <b
              style={{
                display: "inline-block",
                marginTop: 1,
                background: "lightblue",
                width: 80,
              }}
            >
              Created
            </b>
            <b
              style={{
                display: "inline-block",
                marginTop: 1,
                background: "lightgrey",
                width: 80,
              }}
            >
              Updated
            </b>
            <b
              style={{
                display: "inline-block",
                marginTop: 1,
                background: "lightblue",
                width: 80,
              }}
            >
              Active
            </b>
            <b
              style={{
                display: "inline-block",
                marginTop: 1,
                background: "lightgrey",
                width: 200,
              }}
            >
              email
            </b>
          </li>
          {premiumUsers?.slice(0, 50).map((user) => (
            <li>
              <span style={{ display: "inline-block", width: 80 }}>
                {user?.premium_changed_at
                  ? getDaysBefore(new Date(user.premium_changed_at))
                  : null}
              </span>
              <span style={{ display: "inline-block", width: 80 }}>
                {getDaysBefore(new Date(user.createdAt))}
              </span>
              <span style={{ display: "inline-block", width: 80 }}>
                {getDaysBefore(new Date(user.updatedAt))}
              </span>
              <span style={{ display: "inline-block", width: 80 }}>
                {getDaysBefore(new Date(user.last_active_at))}
              </span>

              <span style={{ display: "inline-block", width: 200 }}>
                {user.email}
              </span>
            </li>
            // <li key={user._id}>{user.premium_expire} - {new Date(user.last_active_at).toDateString()} - {user.email}</li>
          ))}
        </ul> */}
      </div>
    </>
  );
};

export default TrafficSummary;
