// import jsonServerProvider from 'ra-data-json-server';
import { stringify } from "query-string";

import axios from "axios";
import { dateToDateTimeString } from "../utils/convert";
import config from "../config";

export const fetchApi = axios.create({
  baseURL: config.API,
  // timeout: 2000,
  // headers: {
  //   'X-Custom-Header': 'foobar',
  //   access_token: JSON.parse(localStorage.getItem('auth')) && JSON.parse(localStorage.getItem('auth')).token
  // }
});

fetchApi.interceptors.request.use(
  function (options) {
    return {
      ...options,
      headers: {
        "X-Custom-Header": "foobar",
        access_token:
          JSON.parse(localStorage.getItem("auth")) &&
          JSON.parse(localStorage.getItem("auth")).token,
      },
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

fetchApi.interceptors.response.use(
  function (res) {
    localStorage.setItem(
      "auth",
      JSON.stringify({
        token: res.headers && res.headers.access_token,
      })
    );
    return res;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    // sort
    let sort = field;
    if (!field || field == "id") {
      sort = "-createdAt";
    } else {
      if (!order || order == "DESC") {
        sort = "-" + sort;
      }
    }

    // if (params.filter.id) {
    //   params._id = params.id
    // }

    // if (resource === "categories") {
    //   params.filter = { ...params.filter, post_count: { $gt: -1 } };
    // }

    const query = {
      sort,
      page,
      limit: perPage,
      filter: JSON.stringify(params.filter),
    };
    if (params.populate) {
      query.populate = params.populate;
    }

    let url = `/${resource}?${stringify(query)}`;
    if (resource === "posts" && localStorage.getItem("posts-search-keyword")) {
      url = `/${resource}/search?${stringify({
        keyword: localStorage.getItem("posts-search-keyword"),
      })}`;
    }
    console.log("getList", resource, params, url);

    return fetchApi({
      method: "get",
      url,
    })
      .then(function ({ data }) {
        return {
          data:
            (data.docs &&
              data.docs.map((doc) => {
                doc.id = doc._id;
                doc.key = doc._id;
                delete doc._id;
                doc.createdAt = dateToDateTimeString(new Date(doc.createdAt));
                doc.updatedAt = dateToDateTimeString(new Date(doc.updatedAt));
                // doc.last_push_at = dateToDateTimeString(new Date(doc.last_push_at))

                return doc;
              })) ||
            [],
          total: data.totalDocs || 10,
        };
      })
      .catch((err) => {
        console.log("error getList", err && err.message);
      });
  },

  getOne: (resource, params) => {
    return fetchApi({
      method: "get",
      url: `/${resource}/${params.id}`,
    })
      .then(function ({ data }) {
        data.id = data._id;
        delete data._id;
        return {
          data,
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  getMany: (resource, params) => {
    console.log("getMany", resource, params);
    const query = {
      // filter: JSON.stringify({
      //   _id: params.ids[0]
      // }),
      filter: JSON.stringify({
        _id: { $in: params.ids },
      }),
    };
    const url = `/${resource}?${stringify(query)}`;

    return fetchApi({
      method: "get",
      url,
    })
      .then(function ({ data }) {
        return {
          data:
            data.docs &&
            data.docs.map((doc) => {
              doc.id = doc._id;
              // delete(doc._id)
              return doc;
            }),
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  getManyReference: (resource, params) => {
    console.log("getManyReference", resource, params);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `/${resource}?${stringify(query)}`;

    return fetchApi({
      method: "get",
      url,
    })
      .then(function ({ data }) {
        return {
          data:
            data.docs &&
            data.docs.map((doc) => {
              doc.id = doc._id;
              delete doc._id;
              return doc;
            }),
          total: data.totalDocs,
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  update: (resource, params) => {
    return fetchApi({
      method: "PUT",
      url: `/${resource}/${params.id}`,
      data: params.data,
    })
      .then(function ({ data }) {
        data.id = data._id;
        delete data._id;
        return {
          data,
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({
        id: params.ids,
      }),
    };
    const url = `/${resource}?${stringify(query)}`;

    return fetchApi({
      method: "PUT",
      url,
      body: params.data,
    })
      .then(function ({ data }) {
        return {
          data:
            data.docs &&
            data.docs.map((doc) => {
              doc.id = doc._id;
              delete doc._id;
              return doc;
            }),
          total: data.totalDocs,
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  create: (resource, params) => {
    return fetchApi({
      method: "POST",
      url: `/${resource}`,
      data: params.data,
    })
      .then(function ({ data }) {
        data.id = data._id;
        delete data._id;
        return {
          data,
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  delete: (resource, params) => {
    return fetchApi({
      method: "DELETE",
      url: `/${resource}/${params.id}`,
    })
      .then(function ({ data }) {
        data.id = data._id;
        delete data._id;
        return {
          data,
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({
        id: params.ids,
      }),
    };
    const url = `/${resource}?${stringify(query)}`;

    return fetchApi({
      method: "DELETE",
      url,
    })
      .then(function ({ data }) {
        return {
          data:
            data.docs &&
            data.docs.map((doc) => {
              doc.id = doc._id;
              delete doc._id;
              return doc;
            }),
          total: data.totalDocs,
        };
      })
      .catch((err) => {
        console.log("error", err && err.message);
      });
  },

  searchPosts: (resource, params) => {
    console.log("searchPosts", resource, params);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    // sort
    let sort = field;
    if (!field || field === "id") {
      sort = "-createdAt";
    } else {
      if (!order || order === "DESC") {
        sort = "-" + sort;
      }
    }

    const query = {
      sort,
      page,
      limit: perPage,
      filter: JSON.stringify(params.filter),
      keyword: params.keyword,
    };
    const url = `/posts/search?${stringify(query)}`;
    return fetchApi({
      method: "get",
      url,
    })
      .then(function ({ data }) {
        return {
          data:
            (data.docs &&
              data.docs.map((doc) => {
                doc.id = doc._id;
                doc.key = doc._id;
                delete doc._id;
                doc.createdAt = dateToDateTimeString(new Date(doc.createdAt));
                doc.updatedAt = dateToDateTimeString(new Date(doc.updatedAt));

                return doc;
              })) ||
            [],
          total: data.totalDocs,
        };
      })
      .catch((err) => {
        console.log("error searchPosts", err && err.message);
      });
  },
};

export default dataProvider;

