import React from "react";
import dataProvider from "../providers/dataProvider";

export default function usePost(post_id) {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    dataProvider
      .getOne("posts", {
        id: post_id,
      })
      .then((res) => {
        setPost(res.data);
      });
  }, [post_id]);

  return post;
}
