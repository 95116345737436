import config from "../config";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${config.API}/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    return fetch(request)
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(({ user, auth }) => {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("auth", JSON.stringify(auth));

        location.reload();
      });
  },
  checkError: (error) => {},
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.role ? Promise.resolve(user.role) : Promise.reject();
  },
  getRole: () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.role;
  },
};

export default authProvider;
