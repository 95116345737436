import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  FunctionField,
  SingleFieldList,
  BooleanField,
  DateField,
  NumberInput,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
} from "react-admin";
import { Typography } from "@material-ui/core";

import Pagination from "./common/Pagination";

export const UserList = (props) => (
  <List
    {...props}
    filters={<UserFilter />}
    bulkActionButtons={false}
    pagination={<Pagination />}
  >
    <Datagrid>
      <TextField source="role" />
      <EmailField source="email" />
      <FunctionField
        label="Email verified"
        render={(record) => <>{record.email_verified ? <>✅</> : null}</>}
      />
      <FunctionField
        label="Premium"
        render={(record) => <>{record.is_premium ? <>✅</> : null}</>}
      />
      <NumberField source="uploaded_count" />
      <NumberField source="viewed_count" />
      <NumberField source="liked_count" />
      <NumberField source="following_count" />
      <NumberField source="follower_count" />

      <DateField
        source="premium_changed_at"
        showTime
        options={{ hour12: false }}
      />
      <TextField source="source" />
      <NumberField source="app_version" />
      <TextField source="platform_os" />
      <NumberField source="platform_version" />
      <NumberField source="timezone_offset" />
      <DateField source="last_active_at" showTime options={{ hour12: false }} />
      <NumberField source="mail_sent_count" />
      <NumberField source="mail_opened_count" />
      <NumberField source="mail_clicked_count" />
      <FunctionField
        label="Mail on"
        render={(record) => (
          <>{record.mail_is_active_subscriber ? <>✅</> : null}</>
        )}
      />

      <TextField source="feedback" />
      <TextField source="feedback_message" />
      <TextField source="mail_unsubscribed_reason" label="Mail unsub reason" />
      <DateField
        source="mail_last_sent_at"
        showTime
        options={{ hour12: false }}
      />
      {/* <TextField source="noti_device_id"  /> */}
      <NumberField source="noti_pushed_count" />
      <NumberField source="noti_opened_count" />
      <DateField
        source="noti_last_pushed_at"
        showTime
        options={{ hour12: false }}
      />
      <FunctionField
        label="Noti on"
        render={(record) => <>{record.noti_is_active ? <>✅</> : null}</>}
      />

      <NumberField source="app_opened_count" />
      <NumberField source="inter_ads_opened_count" />
      <NumberField source="reward_ads_opened_count" />

      <DateField source="createdAt" showTime options={{ hour12: false }} />
      <DateField source="updatedAt" showTime options={{ hour12: false }} />

      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <BooleanField source="email_verified" /> */}
      <TextInput source="email" />
      <TextInput source="password" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const UserEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const UserEdit = (props) => (
  <Edit actions={<UserEditActions />} {...props}>
    <SimpleForm>
      <EmailField source="email" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
      <BooleanField source="is_premium" />
      <BooleanField source="email_verified" />

      <NumberField source="uploaded_count" />
      <NumberField source="viewed_count" />
      <NumberField source="liked_count" />
      <NumberField source="following_count" />
      <NumberField source="follower_count" />

      <DateField
        source="premium_changed_at"
        showTime
        options={{ hour12: false }}
      />
      <TextField source="source" />
      <NumberField source="app_version" />
      <TextField source="platform_os" />
      <NumberField source="platform_version" />
      <NumberField source="timezone_offset" />
      <DateField source="last_active_at" showTime options={{ hour12: false }} />
      <NumberField source="mail_sent_count" />
      <NumberField source="mail_opened_count" />
      <NumberField source="mail_clicked_count" />
      <FunctionField
        label="Mail on"
        render={(record) => (
          <>{record.mail_is_active_subscriber ? <>✅</> : null}</>
        )}
      />

      <TextField source="feedback" />
      <TextField source="mail_unsubscribed_reason" label="Mail unsub reason" />
      <DateField
        source="mail_last_sent_at"
        showTime
        options={{ hour12: false }}
      />
      {/* <TextField source="noti_device_id"  /> */}
      <NumberField source="noti_pushed_count" />
      <NumberField source="noti_opened_count" />
      <DateField
        source="noti_last_pushed_at"
        showTime
        options={{ hour12: false }}
      />
      <FunctionField
        label="Noti on"
        render={(record) => <>{record.noti_is_active ? <>✅</> : null}</>}
      />

      <NumberField source="inter_ads_opened_count" />
      <NumberField source="reward_ads_opened_count" />

      <DateField source="createdAt" showTime options={{ hour12: false }} />
      <DateField source="updatedAt" showTime options={{ hour12: false }} />
    </SimpleForm>
  </Edit>
);

export const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source="email" alwaysOn />
    <SelectInput
      alwaysOn
      source="role"
      choices={[
        { id: "SUPPERADMIN", name: "SUPPERADMIN" },
        { id: "ADMIN", name: "ADMIN" },
        { id: "ASSISTANT", name: "ASSISTANT" },
        { id: "USER", name: "USER" },
      ]}
    />
    <SelectInput
      alwaysOn
      source="source"
      choices={[
        { id: "LOGIN", name: "LOGIN" },
        { id: "SUBSCRIBE", name: "SUBSCRIBE" },
        { id: "FIREBASE", name: "FIREBASE" },
      ]}
    />
    <SelectInput
      alwaysOn
      source="platform_os"
      choices={[
        { id: "ios", name: "ios" },
        { id: "android", name: "android" },
      ]}
    />
    <NumberInput source="app_version" alwaysOn />
    <NumberInput source="platform_version" alwaysOn />
    <NumberInput source="timezone_offset" alwaysOn />
    <SelectInput
      alwaysOn
      source="feedback"
      choices={[
        { id: "I_LOVE_IT", name: "I_LOVE_IT" },
        { id: "I_DONT_LIKE_IT", name: "I_DONT_LIKE_IT" },
      ]}
    />
    <BooleanInput source="is_premium" alwaysOn />
    <BooleanInput source="email_verified" />
    <BooleanInput source="mail_is_active_subscriber" />
    <BooleanInput source="noti_is_active" />
    <BooleanInput source="is_deleted" />
  </Filter>
);
