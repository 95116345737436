import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { Typography, Grid } from "@material-ui/core";
import Pagination from "./common/Pagination";

const BugPanel = (item) => {
  const bug = item.record;
  return (
    <div>
      <Grid container style={{ backgroundColor: "lightgrey" }}>
        <Grid item xs={12} md={6} style={{ maxWidth: 800, overflow: "scroll" }}>
          <pre style={{}}>{JSON.stringify(bug.req, null, 2)}</pre>
        </Grid>
        <Grid item xs={12} md={6} style={{ maxWidth: 800, overflow: "scroll" }}>
          <pre>{JSON.stringify(bug.res, null, 2)}</pre>
        </Grid>
      </Grid>
    </div>
  );
};

export const BugList = (props) => (
  <List
    {...props}
    filters={<BugFilter />}
    bulkActionButtons={false}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="expand" expand={<BugPanel />}>
      <DateField source="createdAt" showTime options={{ hour12: false }} />
      <NumberField source="status_code" />
      <NumberField source="response_time" />
      <TextField source="message" />
      <TextField source="user_role" />
      <TextField source="method" />
      <TextField source="url" />
      <TextField source="user_email" />
      <TextField source="ip" />
      <TextField source="user_agent" />
    </Datagrid>
  </List>
);

export const BugCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <BooleanField source="email_verified" /> */}
      <TextInput source="email" />
      <TextInput source="password" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const BugEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);
const Aside = ({ record }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Bug details</Typography>
    {record && (
      <Typography variant="body2">Creation date: {record.createdAt}</Typography>
    )}
  </div>
);
export const BugEdit = (props) => (
  <Edit actions={<BugEditActions />} {...props}>
    <SimpleForm>
      <EmailField source="email" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const BugFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="status_code"
      choices={[
        { id: "200", name: "200" },
        { id: "304", name: "304" },
      ]}
    />
    <TextInput source="status_code" alwaysOn />
    <TextInput source="user_email" alwaysOn />
  </Filter>
);
