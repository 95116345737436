export default [
  {
    post_count: 59,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb65",
    name: "Relationship",
    color: "#FFAE55",
    createdAt: "2022-04-20T13:16:40.137Z",
    updatedAt: "2023-07-27T11:23:30.231Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Relationship.png",
  },
  {
    post_count: 122,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb66",
    name: "Study",
    color: "#ffc947",
    createdAt: "2022-04-20T13:16:40.137Z",
    updatedAt: "2023-07-27T11:23:30.266Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Study.png",
  },
  {
    post_count: 161,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb67",
    name: "Technology",
    color: "#ff8a50",
    createdAt: "2022-04-20T13:16:40.137Z",
    updatedAt: "2023-07-27T11:23:30.388Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Technology.png",
  },
  {
    post_count: 33,
    unlock: "REWARD_ADS",
    _id: "626007b822aa314a379ffb68",
    name: "Drinking",
    color: "#d3b8ae",
    createdAt: "2022-04-20T13:16:40.137Z",
    updatedAt: "2023-07-27T11:23:30.418Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Tipsy.png",
  },
  {
    post_count: 3,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb69",
    name: "Travel",
    color: "#8eacbb",
    createdAt: "2022-04-20T13:16:40.137Z",
    updatedAt: "2023-07-27T11:23:30.480Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Travel.png",
  },
  {
    post_count: 0,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb6a",
    name: "Diy",
    color: "#8eacbb",
    createdAt: "2022-04-20T13:16:40.137Z",
    updatedAt: "2023-02-13T13:29:33.394Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/DIY.png",
  },
  {
    post_count: 113,
    unlock: "REWARD_ADS",
    _id: "626007b822aa314a379ffb5e",
    name: "Funny",
    color: "#67daff",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-07-30T19:44:21.743Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Funny.png",
  },
  {
    post_count: 337,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb5f",
    name: "Health",
    color: "#5CD0D8",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-07-27T11:23:30.040Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Health.png",
  },
  {
    post_count: 4,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb60",
    name: "House",
    color: "#52c7b8",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-07-27T11:23:30.074Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/House.png",
  },
  {
    post_count: 28,
    unlock: "REWARD_ADS",
    _id: "626007b822aa314a379ffb63",
    name: "Nature",
    color: "#e4e65e",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-07-27T11:23:30.106Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Nature.png",
  },
  {
    post_count: 109,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb62",
    name: "Money",
    color: "#aee571",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-08-01T22:16:28.361Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Money.png",
  },
  {
    post_count: 1,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb64",
    name: "Parenting",
    color: "#ffd95a",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-07-27T11:23:30.169Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Parenting.png",
  },
  {
    post_count: 189,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb61",
    name: "Life",
    color: "#80e27e",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-07-27T11:23:30.200Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Life Tips.png",
  },
  {
    post_count: 0,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb5c",
    name: "Fitness",
    color: "#aab6fe",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2022-09-10T04:58:27.443Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Fitness.png",
  },
  {
    post_count: 205,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb5d",
    name: "Food",
    color: "#6ec6ff",
    createdAt: "2022-04-20T13:16:40.136Z",
    updatedAt: "2023-07-28T16:35:21.474Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Food.png",
  },
  {
    post_count: 8,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb57",
    name: "Other",
    color: "#cfcfcf",
    createdAt: "2022-04-20T13:16:40.135Z",
    updatedAt: "2023-07-27T11:23:27.024Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Other.png",
  },
  {
    post_count: 0,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb5a",
    name: "Cures",
    color: "#df78ef",
    createdAt: "2022-04-20T13:16:40.135Z",
    updatedAt: "2022-09-10T04:58:26.561Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Cures.png",
  },
  {
    post_count: 245,
    unlock: "PREMIUM",
    _id: "626007b822aa314a379ffb5b",
    name: "Everyday",
    color: "#c7a4ff",
    createdAt: "2022-04-20T13:16:40.135Z",
    updatedAt: "2023-07-27T11:23:27.551Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Everyday.png",
  },
  {
    post_count: 227,
    unlock: "REWARD_ADS",
    _id: "626007b822aa314a379ffb58",
    name: "General",
    color: "#ff867c",
    createdAt: "2022-04-20T13:16:40.135Z",
    updatedAt: "2023-07-31T17:11:24.918Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/General.png",
  },
  {
    post_count: 38,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb59",
    name: "Cleaning",
    color: "#ff77a9",
    createdAt: "2022-04-20T13:16:40.135Z",
    updatedAt: "2023-07-27T11:23:30.330Z",
    image:
      "https://raw.githubusercontent.com/hungfhp/binghacks-assets/main/category/images-4x/Cleaning.png",
  },
  {
    post_count: 4,
    unlock: "FREE",
    _id: "626007b822aa314a379ffb56",
    name: "App Tutorial",
    color: "#52c7b8",
    createdAt: "2022-04-20T13:16:40.134Z",
    updatedAt: "2023-08-01T16:51:04.969Z",
    image: "",
  },
];
