import { Layout, AppBar } from 'react-admin';
import * as React from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

const ConfigurationMenu =React.forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to="/configuration"
        primaryText="Configuration"
        leftIcon={<SettingsIcon />}
        onClick={onClick} // close the menu on click
    />
));

const MyUserMenu = props =>{ 

  const user = JSON.parse(localStorage.getItem("user")) || {}

  return (
  <>
    {user.role} - {user.name} - {user.email}
    <UserMenu {...props}>
    </UserMenu>
  </>
)};

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

export default MyLayout;