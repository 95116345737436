import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  FunctionField,
} from "react-admin";
import { Typography, Grid } from "@material-ui/core";
import Pagination from "./common/Pagination";
import { Link } from "react-router-dom";

const TrafficPanel = (item) => {
  let traffic = item.record;
  try {
    traffic.res.data = JSON.parse(traffic.res.data);
  } catch (error) {
    console.error(error);
    console.log(traffic.res.data);
  }

  return (
    <div>
      <Grid container style={{ backgroundColor: "lightgrey" }}>
        <Grid item xs={12} md={6} style={{ maxWidth: 800, overflow: "scroll" }}>
          <pre style={{}}>{JSON.stringify(traffic.req, null, 2)}</pre>
        </Grid>
        <Grid item xs={12} md={6} style={{ maxWidth: 800, overflow: "scroll" }}>
          <pre>{JSON.stringify(traffic.res, null, 2)}</pre>
        </Grid>
      </Grid>
    </div>
  );
};
const rowStyle = (record, index) => ({
  backgroundColor:
    Number(record.response_time) > 1000 ? "rgb(255 238 238)" : "white",
});
export const TrafficList = (props) => {
  return (
    <List
      {...props}
      filters={<TrafficFilter />}
      bulkActionButtons={false}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="expand" expand={<TrafficPanel />} rowStyle={rowStyle}>
        <DateField source="createdAt" showTime options={{ hour12: false }} />
        <FunctionField
          label="Platform"
          render={(record) => (
            <div>
              {record.req?.platform_os} {record.req?.platform_version}
            </div>
          )}
        />
        <FunctionField
          label="Version"
          render={(record) => <div>{record.req?.app_version}</div>}
        />
        <NumberField source="status_code" />
        <FunctionField
          label="Response time"
          render={(record) => (
            <div
              style={{
                color: Number(record.response_time) > 1000 ? "red" : "",
              }}
            >
              {record.response_time}
            </div>
          )}
        />
        <TextField source="user_role" />
        <TextField source="method" />
        <TextField source="url" />
        <FunctionField
          label="User email"
          render={(record) => (
            <Link
              to={`/users?displayedFilters=%7B%7D&filter=%7B"email"%3A"${record.user_email}"%7D`}
            >
              {record.user_email}
            </Link>
          )}
        />
        <TextField source="ip" />
        <TextField source="user_agent" />
      </Datagrid>
    </List>
  );
};

export const TrafficCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <BooleanField source="email_verified" /> */}
      <TextInput source="email" />
      <TextInput source="password" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const TrafficEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);
const Aside = ({ record }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Traffic details</Typography>
    {record && (
      <Typography variant="body2">Creation date: {record.createdAt}</Typography>
    )}
  </div>
);
export const TrafficEdit = (props) => (
  <Edit actions={<TrafficEditActions />} {...props}>
    <SimpleForm>
      <EmailField source="email" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const TrafficFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="status_code"
      choices={[
        { id: "500", name: "500" },
        { id: "404", name: "404" },
        { id: "401", name: "401" },
        { id: "200", name: "200" },
      ]}
    />
    <TextInput source="user_email" alwaysOn />
  </Filter>
);
