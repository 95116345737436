import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  FunctionField,
} from "react-admin";
import { Typography } from "@material-ui/core";

export const IapPanel = (props) => {
  const iap = props.record;

  return (
    <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
      <pre>{JSON.stringify(iap.ios_callback_info, null, 2)}</pre>
    </div>
  );
};

export const IapList = (props) => (
  <List {...props} filters={<IapFilter />} bulkActionButtons={false}>
    <Datagrid expand={<IapPanel />}>
      <DateField source="createdAt" showTime options={{ hour12: false }} />
      <FunctionField
        label="History"
        render={(record) => (
          <div>
            {record.history.map((receipt) => receipt.slice(0, 10)).join(", ")}
          </div>
        )}
      />
      <TextField source="log" />
      <TextField source="purchase" />
      <FunctionField
        label="Receipt"
        render={(record) => <div>{record.receipt?.slice(0, 10)}</div>}
      />
      <TextField source="subscriptionItemId" />
      <ReferenceField label="User" source="user" reference="users">
        <TextField source="email" />
      </ReferenceField>

      <FunctionField
        label="History"
        render={(record) => <div>{record.ios_callback_info?.subtype}</div>}
      />
    </Datagrid>
  </List>
);

export const IapCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="password" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const IapEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);
const Aside = ({ record }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Iap details</Typography>
    {record && (
      <Typography variant="body2">Creation date: {record.createdAt}</Typography>
    )}
  </div>
);
export const IapEdit = (props) => (
  <Edit actions={<IapEditActions />} {...props}>
    <SimpleForm>
      <EmailField source="email" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const IapFilter = (props) => (
  <Filter {...props}>
    {/* <SearchInput source="features" alwaysOn /> */}
    <BooleanInput label="active" source="active" defaultValue={false} />
    <TextInput
      label="platform"
      source="platform"
      defaultValue={false}
      alwaysOn
    />
    <TextInput
      label="appVersion"
      source="appVersion"
      defaultValue={false}
      alwaysOn
    />
  </Filter>
);
