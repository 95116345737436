import React from "react";
import dataProvider from "../providers/dataProvider";

export default function useUsers(params) {
  const [users, setUsers] = React.useState(null);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (params && params.pagination && params.pagination.page) {
      dataProvider.getList("users", params).then((res) => {
        setUsers(res.data);
        setTotal(res.total);
      });
    }
  }, []);

  return { users, total };
}
