import categories from "./categories";

console.log("ENV", process.argv[2] || process.env.NODE_ENV);

const configByEnv = {
  development: {
    // API: "http://localhost:8080/v2/api",
    // API: "https://api.bringhacks.com/staging/v2/api",
    API: "https://api.bringhacks.com/v2/api",
    GOOGLE_CLIENT_ID: {
      WEB: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      IOS: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      ANDROID:
        "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
    },
    GOOGLE_CLIENT_SECRET: {
      WEB: "he_NV46T5e7htEqEiDemji0V",
      IOS: "he_NV46T5e7htEqEiDemji0V",
      ANDROID: "he_NV46T5e7htEqEiDemji0V",
    },

    FACEBOOK_CLIENT_ID: {
      WEB: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      IOS: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      ANDROID:
        "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
    },
    FACEBOOK_CLIENT_SECRET: {
      WEB: "he_NV46T5e7htEqEiDemji0V",
      IOS: "he_NV46T5e7htEqEiDemji0V",
      ANDROID: "he_NV46T5e7htEqEiDemji0V",
    },
  },
  staging: {
    API: "https://api.bringhacks.com/staging/v2/api",
    GOOGLE_CLIENT_ID: {
      WEB: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      IOS: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      ANDROID:
        "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
    },
    GOOGLE_CLIENT_SECRET: {
      WEB: "he_NV46T5e7htEqEiDemji0V",
      IOS: "he_NV46T5e7htEqEiDemji0V",
      ANDROID: "he_NV46T5e7htEqEiDemji0V",
    },

    FACEBOOK_CLIENT_ID: {
      WEB: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      IOS: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      ANDROID:
        "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
    },
    FACEBOOK_CLIENT_SECRET: {
      WEB: "he_NV46T5e7htEqEiDemji0V",
      IOS: "he_NV46T5e7htEqEiDemji0V",
      ANDROID: "he_NV46T5e7htEqEiDemji0V",
    },
  },
  production: {
    API: "https://api.bringhacks.com/v2/api",
    GOOGLE_CLIENT_ID: {
      WEB: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      IOS: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      ANDROID:
        "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
    },
    GOOGLE_CLIENT_SECRET: {
      WEB: "he_NV46T5e7htEqEiDemji0V",
      IOS: "he_NV46T5e7htEqEiDemji0V",
      ANDROID: "he_NV46T5e7htEqEiDemji0V",
    },

    FACEBOOK_CLIENT_ID: {
      WEB: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      IOS: "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
      ANDROID:
        "326660146166-13d42n3v919ifshc9rnmqnfpot4lma9j.apps.googleusercontent.com",
    },
    FACEBOOK_CLIENT_SECRET: {
      WEB: "he_NV46T5e7htEqEiDemji0V",
      IOS: "he_NV46T5e7htEqEiDemji0V",
      ANDROID: "he_NV46T5e7htEqEiDemji0V",
    },
  },
};

const time = {
  SECOND: 1000,
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
};

const env = process.env.NODE_ENV || "development";
const config = configByEnv[env];

const my_config = {
  ENV: env,
  API: config.API,

  TIME: time,

  MODEL: {
    AUTH: {
      JWT_PRIVATE_KEY: "My jwt private keyasdas",
      GOOGLE_CLIENT_ID: config.GOOGLE_CLIENT_ID,
      GOOGLE_CLIENT_SECRET: config.GOOGLE_CLIENT_SECRET,
      FACEBOOK_CLIENT_ID: config.FACEBOOK_CLIENT_ID,
      FACEBOOK_CLIENT_SECRET: config.FACEBOOK_CLIENT_SECRET,
      TOKEN_TYPE: {
        ACCESS: "ACCESS",
        REFRESH: "REFRESH",
        RESET_PASSWORD: "RESET_PASSWORD",
      },
      TOKEN_EXPIRE: {
        ACCESS: 30 * time.SECOND, // 30 s
        // ACCESS: 1 * time.DAY, // 1 day
        REFRESH: 60 * time.DAY, // 60 days
        RESET_PASSWORD: 10 * time.MINUTE, // 10m
      },
    },
    USER: {
      ROLE: {
        VIEWER: "VIEWER",
        USER: "USER",
        ASSISTANT: "ASSISTANT",
        ADMIN: "ADMIN",
        SUPPERADMIN: "SUPPERADMIN",
      },
      GENDER: {
        MALE: "MALE",
        FEMALE: "FEMALE",
        OTHER: "OTHER",
      },
    },
    POST: {
      STATUS: {
        REVIEWING: "REVIEWING",
        REPORTED: "REPORTED",
        EDITING: "EDITING",
        PUBLISHED: "PUBLISHED",
        UNPUBLISHED: "UNPUBLISHED",
      },
      TYPE: {
        LIFEHACK: "LIFEHACK",
        TUTORIAL: "TUTORIAL",
        PREMIUM: "PREMIUM",
        // NATIVE_ADS: "NATIVE_ADS",
        // REMOVE_ADS: "REMOVE_ADS",
        // AFFILIATE_ADS: "AFFILIATE_ADS",
        // MEDIA: "MEDIA",
      },
      STYLE: {
        STYLE_1: "STYLE_1",
        STYLE_2: "STYLE_2",
        STYLE_3: "STYLE_3",
        STYLE_4: "STYLE_4",
        STYLE_5: "STYLE_5",
        STYLE_6: "STYLE_6",
        STYLE_7: "STYLE_7",
        STYLE_8: "STYLE_8",
        STYLE_9: "STYLE_9",
        STYLE_10: "STYLE_10",
      },
    },
    POST_REPORT: {
      TYPE: {
        NEW_SUBMIT: "NEW_SUBMIT",
        OUT_DATE: "OUT_DATE",
        WRONG_GRAMMAR: "WRONG_GRAMMAR",
        POOR_IMAGE: "POOR_IMAGE",
        WRONG_CONTENT: "WRONG_CONTENT",
        OTHER: "OTHER",
      },
    },
    TREND: {},
  },

  DATA: {
    CATEGORY: categories,
  },
};

console.log("SERVER API", my_config.API);
export default my_config;
