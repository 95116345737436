import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  ReferenceArrayField,
  ChipField,
  useGetIdentity,
  useGetOne,
} from "react-admin";

import { Typography, Grid } from "@material-ui/core";

import config from "../config";
import util from "../service/util";

import { PostEdit, PostPanel } from "./Post";

export const PostReportList = (props) => {
  return (
    <List
      {...props}
      filters={<PostReportFilter />}
      bulkActionButtons={false}
      filterDefaultValues={filterDefaultValues}
    >
      <Datagrid rowClick="expand" expand={<PostReportPanel />}>
        <ReportedCountField label="Reporters" />
        <BooleanField source="edited" />
        <NumberField source="post.status" />
        {/* <ReferenceField label="Ref Post" source="post._id" reference="posts">
          <TextField source="slug" />
        </ReferenceField> */}
        <DateField source="createdAt" showTime options={{ hour12: false }} />
        <DateField source="updatedAt" showTime options={{ hour12: false }} />
        <ReportContentField label="Type" />
        <TextField source="post.note" />
        <TextField source="assistant" label="Assistant ID" />
        <ReferenceField
          label="Assistant email"
          source="assistant"
          reference="users"
        >
          <TextField source="email" />
        </ReferenceField>
        <ActionField label="Edit" permissions={props.permissions} />
        {/* <DeleteButton/> */}
      </Datagrid>
    </List>
  );
};
const ReportedCountField = ({ record }) => {
  return <div>{record.reporters.length}</div>;
};
const ReportContentField = ({ record }) => {
  return (
    <>
      {record.report_contents &&
        record.report_contents.map((content, i) => {
          return (
            <div key={i + 1}>
              {util.getKeyByValue(config.MODEL.POST_REPORT.TYPE, content)}
            </div>
          );
        })}
    </>
  );
};
const ActionField = (props) => {
  const { identity: user, loading } = useGetIdentity();
  if (loading) return "...";
  if (
    !props.record.edited ||
    props.record.assistant == user._id ||
    user.role === "SUPPERADMIN"
  ) {
    return <EditButton {...props}></EditButton>;
  } else {
    return "Edited";
  }
};
const PostReportPanel = (item) => {
  const pr = item.record;

  return (
    <div>
      {pr.edited ? (
        <Grid container spacing={2}>
          <Grid item md={6}>
            Before:
            <PostPanel record={JSON.parse(pr.before)} />
          </Grid>
          <Grid item md={6}>
            After:
            <PostPanel record={JSON.parse(pr.after)} />
          </Grid>
        </Grid>
      ) : (
        <PostPanel record={pr.post} />
      )}

      <br />
    </div>
  );
};
const PostReportFilter = (props) => (
  <Filter {...props}>
    <BooleanInput
      label="Edited"
      source="edited"
      defaultValue={false}
      alwaysOn
    />
    <TextInput label="Assistant ID" source="assistant" alwaysOn />
  </Filter>
);
const filterDefaultValues = {
  edited: false,
  // assistant: null
};

export const PostReportCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <BooleanField source="email_verified" /> */}
      <TextInput source="post_id" label="Post ID" />
      <SelectInput
        source="type"
        choices={util.toChoices(config.MODEL.POST_REPORT.TYPE)}
      />
    </SimpleForm>
  </Create>
);

const PostReportEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const Aside = ({ record, props }) => {
  if (!record || !record.post || !record.post._id) return null;
  let props_str = JSON.stringify(props)
    .replaceAll("post-report", "post")
    .replaceAll(props.id, record.post._id);

  const post_props = JSON.parse(props_str);

  return (
    <div style={{ width: 1000, margin: "1em" }}>
      <PostEdit {...post_props} />
    </div>
  );
};

export const PostReportEdit = (props) => {
  return (
    <Edit {...props} aside={<Aside props={props} />}>
      <SimpleForm>
        <TextField source="post._id" />
        <ReferenceArrayField
          label="Reporters"
          reference="users"
          source="reporters"
        >
          <SingleFieldList>
            <ChipField source="email" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="report_contents" />
        <BooleanInput source="edited" />
      </SimpleForm>
    </Edit>
  );
};
