import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import Pagination from "./common/Pagination";

const NotificationPanel = (item) => {
  const post = item.record;
  return (
    <div>
      <br />
      {post &&
        post.contents &&
        post.contents.map((content) => {
          return (
            <div key={content._id}>
              {content.style}
              <br />
              {content.text}
              <br />
              <br />
            </div>
          );
        })}
    </div>
  );
};

export const NotificationList = (props) => (
  <List
    {...props}
    filters={<NotificationFilter />}
    bulkActionButtons={false}
    pagination={<Pagination />}
  >
    <Datagrid>
      <DateField source="createdAt" showTime options={{ hour12: false }} />

      <DateField source="pushed_at" showTime options={{ hour12: false }} />
      <TextField source="opened" />
      <DateField source="opened_at" showTime options={{ hour12: false }} />
      <TextField source="type" />
      <TextField source="title" />
      <TextField source="body" />
      <TextField source="data" />

      <TextField source="user._id" />
      <TextField source="user.email" />
      <NumberField source="user.app_version" />
      <TextField source="user.platform_os" />
      <NumberField source="user.timezone_offset" />
      <NumberField source="user.noti_pushed_count" />
      <NumberField source="user.noti_opened_count" />
      <DateField
        source="user.noti_last_pushed_at"
        showTime
        options={{ hour12: false }}
      />
      <BooleanField source="user.noti_is_active" />
    </Datagrid>
  </List>
);

export const NotificationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="password" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const NotificationEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);
const Aside = ({ record }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Notification details</Typography>
    {record && (
      <Typography variant="body2">Creation date: {record.createdAt}</Typography>
    )}
  </div>
);
export const NotificationEdit = (props) => (
  <Edit actions={<NotificationEditActions />} {...props}>
    <SimpleForm>
      <EmailField source="email" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const NotificationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="user" source="user" alwaysOn />
    <TextInput label="type" source="type" alwaysOn />
    <BooleanInput
      label="opened"
      source="opened"
      defaultValue={false}
      alwaysOn
    />
    {/* <TextInput label="user.app_version" source="user.app_version"  alwaysOn />
      <TextInput label="user.platform_os" source="user.platform_os"  alwaysOn /> */}
  </Filter>
);
