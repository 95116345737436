import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  FunctionField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  RichTextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  ReferenceArrayField,
  ChipField,
  useGetIdentity,
  useGetOne,
} from "react-admin";

import { Grid, Link } from "@material-ui/core";

import config from "../config";
import util from "../service/util";

import { PostEdit, PostPanel, PostCard } from "./Post";
import usePost from "../hooks/usePost";

export const PostClickList = (props) => {
  return (
    <List
      {...props}
      filters={<PostClickFilter />}
      bulkActionButtons={false}
      // filterDefaultValues={filterDefaultValues}
    >
      <Datagrid rowClick="expand" expand={<PostClickPanel />}>
        <TextField source="post" />
        <FunctionField
          label="Post"
          style={{
            textAlign: "center",
          }}
          render={(record) => (
            <a href={`/#/posts/${record.post}`}>{record.post}</a>
          )}
        />
        <TextField source="clicked_on" />
        <DateField source="createdAt" showTime options={{ hour12: false }} />
        <DateField source="updatedAt" showTime options={{ hour12: false }} />
        <ReferenceField label="Post" source="post" reference="posts">
          <TextField source="title" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

const PostClickPanel = (props) => {
  const post = usePost(props.record.post);

  return (
    <div>
      {post ? <PostPanel record={post} /> : "Can not view"}
      <br />
    </div>
  );
};
const PostClickFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Clicked on" source="clicked_on" alwaysOn />
    <TextInput label="Post ID" source="post" alwaysOn />
  </Filter>
);
const filterDefaultValues = {
  // edited: false,
  // assistant: null
};

export const PostClickCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <BooleanField source="email_verified" /> */}
      <TextInput source="post_id" label="Post ID" />
      <SelectInput
        source="type"
        choices={util.toChoices(config.MODEL.POST_REPORT.TYPE)}
      />
    </SimpleForm>
  </Create>
);

const Aside = ({ record, props }) => {
  if (!record || !record.post || !record.post._id) return null;
  let props_str = JSON.stringify(props)
    .replaceAll("post-report", "post")
    .replaceAll(props.id, record.post._id);

  const post_props = JSON.parse(props_str);

  return (
    <div style={{ width: 1000, margin: "1em" }}>
      <PostEdit {...post_props} />
    </div>
  );
};

export const PostClickEdit = (props) => {
  return (
    <Edit {...props} aside={<Aside props={props} />}>
      <SimpleForm>
        <TextField source="post._id" />
        <ReferenceArrayField
          label="Reporters"
          reference="users"
          source="reporters"
        >
          <SingleFieldList>
            <ChipField source="email" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="report_contents" />
        <BooleanInput source="edited" />
      </SimpleForm>
    </Edit>
  );
};
