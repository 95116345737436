import React from "react";
import preval from "preval.macro";
// import { subDays } from 'date-fns';

import TrafficSummary from "./TrafficSummary";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;

const Dashboard = () => {
  return (
    <>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <p>
            Built at: {preval`module.exports = new Date().toLocaleString();`}.
          </p>
          <div style={styles.flex}>
            <TrafficSummary />
            <Spacer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
