import React, { Component, useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  FilterButton,
  CreateButton,
  ExportButton,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  RichTextField,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  SortButton,
  useRecordContext,
  ImageField,
  useDataProvider,
  BooleanInput,
  FunctionField,
  Loading,
  ImageInput,
  FileField,
  maxLength,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import {
  Typography,
  TextField as TextFieldMUI,
  Button,
  Grid,
  Stack,
  Box,
  Switch,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNotify } from "react-admin";

import config from "../config/index";
import Pagination from "./common/Pagination";
const POST_STATUS = config.MODEL.POST.STATUS;
const POST_TYPE = config.MODEL.POST.TYPE;
import ASSISTANTS from "../config/assistants";
import usePost from "../hooks/usePost";
import useSimilarPosts from "../hooks/useSimilarPosts";
import usePostChanges from "../hooks/usePostChanges";
import dataProvider from "../providers/dataProvider";

const calculateRate = (liked, viewed) => {
  if (viewed === 0) return 0;

  return Math.round((liked / viewed) * 1000) / 10;
};

export const UnpublishByLowRateButton = ({ record }) => {
  if (!record || record.id == null) {
    return null;
  }
  const [unpublished, setUnpublished] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUnpublish = () => {
    setLoading(true);
    dataProvider
      .update("posts", {
        id: record.id,
        data: {
          status: POST_STATUS.UNPUBLISHED,
          note: "Low rate",
        },
      })
      .then(() => {
        setUnpublished(true);
      })
      .catch(() => {
        setUnpublished(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      onClick={handleUnpublish}
      color="primary"
      disabled={unpublished || loading}
    >
      {loading
        ? "PROCESSING"
        : unpublished
        ? "UNPUBLISHED"
        : "Unpublish (low rate)"}
    </Button>
  );
};

export const PostCard = ({ post }) => {
  return (
    <Paper style={{ marginTop: 10, width: 300, padding: "1em" }}>
      {post &&
        post.contents &&
        post.contents.map((content) => {
          return (
            <Grid container key={content._id}>
              <Grid item xs={12}>
                <div style={{ maxWidth: "300px" }}>
                  {post.category.name}:{" "}
                  <Link to={`/posts/${post._id || post.id}`}>
                    {post.title || post.slug}
                  </Link>
                  <div dangerouslySetInnerHTML={{ __html: content?.html }} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <img alt="" src={content.image} width="300" />
              </Grid>
            </Grid>
          );
        })}
    </Paper>
  );
};

export const PostPanel = (props) => {
  const post = props.record;

  const user_id = post.user?._id || post.user;

  return (
    <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
      <div>
        {post.status} <Link to={`/posts/${post._id}`}>{post.slug}</Link>
      </div>
      <div>
        Creator:{" "}
        <Link to={`/users/${user_id}`}>{post.user?.email || post.user}</Link>
      </div>
      <div>Note: {post.note}</div>

      <PostCard post={post} />

      <div>
        <PostStatistic post={post} />
      </div>
    </div>
  );
};

export const UploadFileToGetUrl = () => {
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = (file) => {
    console.log("Uploading local file:", file);

    setUploadedUrl("");
    setUploading(true);

    if (!file) {
      setUploading(false);
      return;
    }
    const storage = getStorage();
    const storageRef = ref(storage, "images/" + Date.now() + "-" + file.name);

    console.log("Uploading to storage:", file);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(function (downloadURL) {
        console.log("Uploaded url:", downloadURL);
        setUploadedUrl(downloadURL);
        setUploading(false);
      });
    });
  };

  function copyUploadUrl(e) {
    e.target.textContent = "Copied uploaded url";
    setTimeout(() => {
      e.target.textContent = "Copy uploaded url";
    }, 500);
    navigator.clipboard.writeText(uploadedUrl);
  }

  return (
    <Paper style={{ marginTop: 10, width: 300, padding: "1em" }}>
      <SimpleForm toolbar={<></>}>
        <ImageInput
          title="Upload image"
          source="image"
          onChange={handleFileUpload}
          accept="image/*"
        >
          <ImageField
            source="image"
            title="Local file"
            style={{ height: 50 }}
          />
        </ImageInput>
      </SimpleForm>

      {uploading ? <div style={{ textAlign: "center" }}>Uploading </div> : null}

      {uploadedUrl ? (
        <div style={{ textAlign: "center" }}>
          <hr />
          <div style={{ marginBottom: 10 }}>Uploaded image preview:</div>
          <img src={uploadedUrl} height={200} />
          <button onClick={copyUploadUrl} style={{ padding: 10, margin: 10 }}>
            Copy uploaded url
          </button>
        </div>
      ) : null}
    </Paper>
  );
};

export const PostList = (props) => {
  const [listType, setListType] = React.useState("simple");

  const handleChangeListType = (event) => {
    setListType(event.target.value);
  };

  class SearchPostContentInput extends Component {
    handleChange = async (e) => {
      const keyword = e.target.value;
      localStorage.setItem("posts-search-keyword", keyword);
    };

    render() {
      return (
        <TextFieldMUI
          label="Search (must refresh browser after typing text)"
          defaultValue={localStorage.getItem("posts-search-keyword")}
          onChange={this.handleChange}
          variant="outlined"
        />
      );
    }
  }

  return (
    <>
      <SearchPostContentInput />

      <Box style={{ display: "flex", alignItems: "center" }}>
        <b style={{ paddingRight: 16 }}>List Type: </b>
        <FormControl>
          {/* <FormLabel id="demo-row-radio-buttons-group-label">List Type</FormLabel> */}
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={listType}
            onChange={handleChangeListType}
          >
            <FormControlLabel
              value="detail"
              control={<Radio />}
              label="Detail"
            />
            <FormControlLabel
              value="simple"
              control={<Radio />}
              label="Simple"
            />
            <FormControlLabel
              value="statistic"
              control={<Radio />}
              label="Statistic"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {listType === "detail" ? (
        <List
          {...props}
          filters={<PostFilter />}
          bulkActionButtons={false}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="expand" expand={<PostPanel />}>
            <EditButton />
            <TextField source="category.name" label="Category" />
            <TextField source="status" />
            <TextField source="title" label="Summary" />
            <RichTextField
              source="contents[0].html"
              label="Content"
              style={{ width: 100 }}
            />
            <FunctionField
              label="Image"
              style={{
                textAlign: "center",
              }}
              render={(record) => (
                <a href={record.contents[0]?.image} target="_blank">
                  <img height={30} src={record.contents[0]?.image} />
                </a>
              )}
            />
            <NumberField source="clicked_count" label="Clicked" />
            <NumberField source="statistic.recommend_point" label="Recom" />
            <NumberField source="statistic.trend_point" label="Trend" />
            <TextField label="Creator" source="user.email" />
            <DateField
              source="createdAt"
              showTime
              options={{ hour12: false }}
            />
            <DateField
              source="updatedAt"
              showTime
              options={{ hour12: false }}
            />

            <DeleteButton />
          </Datagrid>
        </List>
      ) : listType === "simple" ? (
        <List
          {...props}
          filters={<PostFilter />}
          bulkActionButtons={false}
          pagination={<Pagination />}
        >
          <Datagrid expand={<PostPanel />}>
            <EditButton />
            <TextField source="category.name" label="Category" />
            <NumberField source="status" />
            <TextField source="title" label="Summary" />
            <RichTextField
              source="contents[0].html"
              label="Content"
              style={{ width: 100 }}
            />
            <FunctionField
              label="Image"
              style={{
                textAlign: "center",
              }}
              render={(record) => (
                <a href={record.contents[0]?.image} target="_blank">
                  <img
                    height={100}
                    src={record.contents[0]?.image}
                    style={{
                      padding: "5px",
                      background: "green",
                    }}
                  />
                </a>
              )}
            />
            <FunctionField
              label="Packed image"
              render={(record) => (
                <a href={record?.packed_image_url} target="_blank">
                  <img height={100} src={record?.packed_image_url} />
                </a>
              )}
            />
            <FunctionField
              label="Search"
              render={(record) => (
                <>
                  <a
                    href={`https://www.google.com/search?q=${record.contents[0].text}`}
                    target="_blank"
                  >
                    <Button>Text</Button>
                  </a>
                  <br />
                  <a
                    href={`https://www.google.com/search?q=${record.contents[0].text}&tbm=isch`}
                    target="_blank"
                  >
                    <Button>Images</Button>
                  </a>
                </>
              )}
            />
            <UnpublishByLowRateButton />
          </Datagrid>
        </List>
      ) : (
        // Statistic
        <List
          {...props}
          filters={<PostFilter />}
          bulkActionButtons={false}
          pagination={<Pagination />}
        >
          <Datagrid expand={<PostPanel />}>
            <EditButton />
            <TextField source="category.name" label="Category" />
            <TextField source="status" />

            <NumberField source="statistic.recommend_point" label="Recom" />
            <NumberField source="statistic.trend_point" label="Trend" />

            <NumberField source="statistic.liked_24h" label="L 24h" />
            <NumberField source="statistic.viewed_24h" label="V 24h" />
            <FunctionField
              label="Rate 24h"
              source="statistic.rate_like_view_24h"
              render={(record) => (
                <b>{record.statistic?.rate_like_view_24h}%</b>
              )}
            />

            <NumberField source="statistic.liked_7days" label="L 7D" />
            <NumberField source="statistic.viewed_7days" label="V 7D" />
            <FunctionField
              label="Rate 7D"
              source="statistic.rate_like_view_7days"
              render={(record) => (
                <b>{record.statistic?.rate_like_view_7days}%</b>
              )}
            />

            <NumberField source="statistic.liked_30days" label="L 30D" />
            <NumberField source="statistic.viewed_30days" label="V 30D" />
            <FunctionField
              label="Rate 30D"
              source="statistic.rate_like_view_30days"
              render={(record) => (
                <b>
                  {calculateRate(
                    record.statistic?.liked_30days,
                    record.statistic?.viewed_30days
                  )}
                  %
                </b>
              )}
            />

            <NumberField source="statistic.liked_lifetime" label="L All" />
            <NumberField source="statistic.viewed_lifetime" label="V All" />
            <FunctionField
              label="Rate All"
              source="statistic.rate_like_view_lifetime"
              render={(record) => (
                <b>
                  {calculateRate(
                    record.statistic?.liked_lifetime,
                    record.statistic?.viewed_lifetime
                  )}
                  %
                </b>
              )}
            />
            <DeleteButton />
          </Datagrid>
        </List>
      )}
    </>
  );
};

const PostEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="List Post" />
    <CreateButton basePath={basePath} label="Create Post" />
    <ShowButton basePath={basePath} record={data} />
    <a
      href="https://github.com/hungfhp/binghacks-assets/upload/main/hacks"
      target="_blank"
    >
      <Button>Upload file</Button>
    </a>
    {data?.contents[0]?.text ? (
      <a
        href={`https://www.google.com/search?q=${data?.contents[0]?.text}`}
        target="_blank"
      >
        <Button>Search text</Button>
      </a>
    ) : null}
    <br />
    {data?.contents[0]?.text ? (
      <a
        href={`https://www.google.com/search?q=${data?.contents[0]?.text}&tbm=isch`}
        target="_blank"
      >
        <Button>Search Images</Button>
      </a>
    ) : null}
  </TopToolbar>
);

export const PostCreate = (props) => {
  const notify = useNotify();

  return (
    <h5>Moved to new admin</h5>
  )

  return (
    <Create
      actions={<PostEditActions />}
      {...props}
      aside={
        <div style={{ marginLeft: 10 }}>
          <UploadFileToGetUrl />
        </div>
      }
    >
      <SimpleForm redirect={false}>
        <TextInput
          label="Summary, used when searching Google for more detail (max 50 letters, should less than 8 words)"
          source="title"
          inputProps={{ maxLength: 50 }}
          fullWidth
        />

        <ReferenceInput
          label="Category"
          source="category._id"
          reference="categories"
          perPage={100}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <SelectInput
          source="status"
          defaultValue={POST_STATUS.REVIEWING}
          choices={Object.keys(POST_STATUS)
            .filter((status) => status == POST_STATUS.REVIEWING)
            .map((key) => ({
              id: key,
              name: key,
            }))}
        />

        <TextInput label="note" source="note" fullWidth multiline />

        <ArrayInput source="contents">
          <SimpleFormIterator>
            <RichTextInput
              source="html"
              label="html (max 350 characters)"
              fullWidth
              validate={(value) => {
                if (value && value.length > 350) {
                  notify(
                    `The content must be ${350} characters or less. You entered ${
                      value.length
                    } characters.`,
                    { type: "error" }
                  );
                }
                return undefined;
              }}
            />
            <TextInput label="image url" source="image" fullWidth />
            <FunctionField
              label="Image preview"
              render={(record) =>
                record.image ? <img src={record.image} height={150} /> : null
              }
            />
          </SimpleFormIterator>
        </ArrayInput>

        <TextInput source="source" label="source url" fullWidth />
      </SimpleForm>
    </Create>
  );
};

function PostStatistic(props) {
  const post = props.post;

  if (!post)
    return <Paper style={{ width: 200, padding: "1em" }}>No Post</Paper>;

  const statistic = post.statistic;
  if (!statistic) {
    return (
      <Paper style={{ width: 200, padding: "1em" }}>No Post Statistic</Paper>
    );
  }

  function createRow(
    attribute,
    recommend_point,
    trend_point,
    last_24h,
    last_7days,
    last_30days,
    lifetime
  ) {
    return {
      attribute,
      recommend_point,
      trend_point,
      last_24h,
      last_7days,
      last_30days,
      lifetime,
    };
  }

  const rows = [
    createRow(
      "Liked",
      "",
      "",
      statistic.liked_24h?.toLocaleString(),
      statistic.liked_7days?.toLocaleString(),
      statistic.liked_30days?.toLocaleString(),
      statistic.liked_lifetime?.toLocaleString()
    ),
    createRow(
      "Viewed",
      "",
      "",
      statistic.viewed_24h?.toLocaleString(),
      statistic.viewed_7days?.toLocaleString(),
      statistic.viewed_30days?.toLocaleString(),
      statistic.viewed_lifetime?.toLocaleString()
    ),
    createRow(
      "Rate/Point",
      statistic.recommend_point,
      statistic.trend_point,
      statistic.rate_like_view_24h + "%",
      statistic.rate_like_view_7days + "%",
      calculateRate(statistic.liked_30days, statistic.viewed_30days) + "%",
      calculateRate(statistic.liked_lifetime, statistic.viewed_lifetime) + "%"
    ),
  ];

  return (
    <TableContainer component={Paper} style={{ width: "auto" }}>
      <Table
        style={{ width: "auto" }}
        size="small"
        aria-label="Statistic by time"
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: 5 }}></TableCell>
            <TableCell style={{ padding: 5 }} align="right">
              Recom
            </TableCell>
            <TableCell style={{ padding: 5 }} align="right">
              Trend
            </TableCell>
            <TableCell style={{ padding: 5 }} align="right">
              24H
            </TableCell>
            <TableCell style={{ padding: 5 }} align="right">
              7D
            </TableCell>
            <TableCell style={{ padding: 5 }} align="right">
              30D
            </TableCell>
            <TableCell style={{ padding: 5 }} align="right">
              All
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.attribute}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell style={{ padding: 5 }} component="th" scope="row">
                {row.attribute}
              </TableCell>
              <TableCell style={{ padding: 5 }} align="right">
                <b>{row.recommend_point}</b>
              </TableCell>
              <TableCell style={{ padding: 5 }} align="right">
                <b>{row.trend_point}</b>
              </TableCell>
              <TableCell style={{ padding: 5 }} align="right">
                {row.last_24h}
              </TableCell>
              <TableCell style={{ padding: 5 }} align="right">
                {row.last_7days}
              </TableCell>
              <TableCell style={{ padding: 5 }} align="right">
                {row.last_30days}
              </TableCell>
              <TableCell style={{ padding: 5 }} align="right">
                {row.lifetime}
              </TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            {/* One full width cell in this row */}
            <TableCell colSpan={7} style={{ padding: 5 }}>
              {statistic.viewed_lifetime > 50
                ? "Enough views to evaluate"
                : "Not enough views to evaluate"}
              <br></br>
              Should evaluate when have more than 50 views.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function PostSimilar(props) {
  const post = props.post;

  if (!post)
    return (
      <Paper style={{ width: "100%", padding: "1em" }}>
        Loading similar posts
      </Paper>
    );
  if (!post._id && !post.id)
    return <Paper style={{ width: "100%", padding: "1em" }}>No post id</Paper>;

  const similarPosts = useSimilarPosts(post._id || post.id);

  return (
    <Paper style={{ width: "100%", padding: "1em", marginTop: 16 }}>
      <div>
        <b>Similar posts: {similarPosts.length}</b>
      </div>
      <div>For check not duplicated post before save</div>
      {similarPosts.map((p) => (
        <PostCard key={p._id} post={p} />
      ))}
    </Paper>
  );
}

function PostChanges({ post }) {
  if (!post) return <></>;
  if (!post._id && !post.id)
    return <Paper style={{ width: "100%", padding: "1em" }}>No post id</Paper>;

  const postChanges = usePostChanges(post._id || post.id);

  return (
    <Paper style={{ width: "100%", padding: "1em", marginTop: 16 }}>
      <div>
        <b>Post changed logs:</b>
      </div>
      {/* <div>For check not duplicated post before save</div> */}
      {postChanges.map((p, i) => (
        <div>
          <div>
            {i + 1}) {p?.assistant?.email} at {p?.createdAt}
          </div>
          <div>
            {JSON.parse(p.before || "{}")?.status} ->{" "}
            {JSON.parse(p.after || "{}")?.status}
          </div>
          <div>Note: {JSON.parse(p.after || "{}").note}</div>
          <br />
        </div>
      ))}
    </Paper>
  );
}

export const PostEdit = (props) => {
  const autoBreakLineHtml = (html) => {
    let htmlWithBreakLine = html;
    htmlWithBreakLine = htmlWithBreakLine.replace("1. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("2. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("3. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("4. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("5. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("6. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("7. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("8. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("9. ", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("10. ", "");

    htmlWithBreakLine = htmlWithBreakLine.replace(" 1.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 2.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 3.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 4.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 5.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 6.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 7.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 8.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 9.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace(" 10.", "");

    htmlWithBreakLine = htmlWithBreakLine.replace("1.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("2.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("3.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("4.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("5.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("6.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("7.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("8.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("9.", "");
    htmlWithBreakLine = htmlWithBreakLine.replace("10.", "");

    return htmlWithBreakLine;
  };

  const post = usePost(props.id);
  const notify = useNotify();

  return (
    <h5>Moved to new admin</h5>
  )

  return (
    <Edit
      {...props}
      actions={<PostEditActions />}
      aside={
        <div style={{ marginLeft: 10 }}>
          <PostStatistic post={post} />
          <UploadFileToGetUrl />
          <PostSimilar post={post} />
          <Paper style={{ padding: "1em", marginTop: 16 }}>
            <div>
              <b>Packed image:</b>
            </div>
            <img width={200} src={post?.packed_image_url} />
          </Paper>
          <PostChanges post={post} />
        </div>
      }
      style={{ maxWidth: 1000 }}
    >
      <SimpleForm redirect={false}>
        <TextField label="Creator" source="user.email" />
        <TextField label="Created at" source="createdAt" />

        <TextInput
          label="Summary, used when searching Google for more detail (max 50 letters, should less than 8 words)"
          source="title"
          fullWidth
          inputProps={{ maxLength: 50 }}
        />

        <ReferenceInput
          label="Category"
          source="category._id"
          reference="categories"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        {/* <SelectInput
          alwaysOn
          label="Category"
          source="category._id"
          choices={CATEGORY.map((cate) => ({
            id: cate._id,
            name: cate.name,
          }))}
        /> */}

        <SelectInput
          source="status"
          choices={Object.keys(POST_STATUS).map((key) => ({
            id: key,
            name: key,
          }))}
        />

        <TextInput label="note" source="note" fullWidth multiline />

        <ArrayInput source="contents">
          <SimpleFormIterator>
            <RichTextInput
              source="html"
              label="html (max 350 characters)"
              fullWidth
              validate={(value) => {
                if (value && value.length > 350) {
                  notify(
                    `The content must be ${350} characters or less. You entered ${
                      value.length
                    } characters.`,
                    { type: "error" }
                  );
                }
                return undefined;
              }}
            />
            {/* <TextInput source="html" label="html raw" fullWidth multiline /> */}
            {/* <TextInput source="text" label="text" fullWidth multiline /> */}
            <TextInput label="image url" source="image" fullWidth />
            <FunctionField
              label="Image preview"
              render={(record) =>
                record.image ? <img src={record.image} height={150} /> : null
              }
            />
            {/* <TextInput label="video url" source="video" fullWidth /> */}
            {/* <TextInput label="gif url" source="gif" fullWidth /> */}
          </SimpleFormIterator>
        </ArrayInput>

        <TextInput source="source" label="source url" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export const PostFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="status"
      choices={Object.keys(POST_STATUS).map((key) => ({
        id: key,
        name: key,
      }))}
    />

    <SelectInput
      alwaysOn
      source="type"
      choices={Object.keys(POST_TYPE).map((key) => ({
        id: key,
        name: key,
      }))}
    />

    <ReferenceInput
      alwaysOn
      label="Category"
      source="category._id"
      reference="categories"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <SelectInput
      alwaysOn
      source="user"
      label="Creator"
      choices={ASSISTANTS.map((user) => ({
        id: user._id,
        name: user.email,
      }))}
    />

    <SelectInput
      alwaysOn
      source="min_viewed_30days"
      label="Viewed 30D"
      choices={[
        {
          id: 10,
          name: "> 10 views",
        },
        {
          id: 20,
          name: "> 20 views",
        },
        {
          id: 30,
          name: "> 30 views",
        },
        {
          id: 40,
          name: "> 40 views",
        },
        {
          id: 50,
          name: "> 50 views",
        },
        {
          id: 60,
          name: "> 60 views",
        },
        {
          id: 70,
          name: "> 70 views",
        },
        {
          id: 80,
          name: "> 80 views",
        },
      ]}
    />

    <SelectInput
      alwaysOn
      source="max_rate_like_view_30days"
      label="Rate 30D"
      choices={[
        {
          id: 2,
          name: "< 2%",
        },
        {
          id: 3,
          name: "< 3%",
        },
        {
          id: 4,
          name: "< 4%",
        },
        {
          id: 5,
          name: "< 5%",
        },
        {
          id: 6,
          name: "< 6%",
        },
        {
          id: 7,
          name: "< 7%",
        },
        {
          id: 8,
          name: "< 8%",
        },
        {
          id: 9,
          name: "< 9%",
        },
        {
          id: 10,
          name: "< 10%",
        },
        {
          id: 11,
          name: "< 11%",
        },
        {
          id: 12,
          name: "< 12%",
        },
        {
          id: 13,
          name: "< 14%",
        },
        {
          id: 14,
          name: "< 14%",
        },
        {
          id: 15,
          name: "< 15%",
        },
      ]}
    />

    <SelectInput
      alwaysOn
      source="min_viewed_lifetime"
      label="Viewed all"
      choices={[
        {
          id: 10,
          name: "> 10 views",
        },
        {
          id: 20,
          name: "> 20 views",
        },
        {
          id: 30,
          name: "> 30 views",
        },
        {
          id: 40,
          name: "> 40 views",
        },
        {
          id: 50,
          name: "> 50 views",
        },
        {
          id: 60,
          name: "> 60 views",
        },
        {
          id: 70,
          name: "> 70 views",
        },
        {
          id: 80,
          name: "> 80 views",
        },
      ]}
    />

    <SelectInput
      alwaysOn
      source="max_rate_like_view_lifetime"
      label="Rate all"
      choices={[
        {
          id: 2,
          name: "< 2%",
        },
        {
          id: 3,
          name: "< 3%",
        },
        {
          id: 4,
          name: "< 4%",
        },
        {
          id: 5,
          name: "< 5%",
        },
        {
          id: 6,
          name: "< 6%",
        },
        {
          id: 7,
          name: "< 7%",
        },
        {
          id: 8,
          name: "< 8%",
        },
        {
          id: 9,
          name: "< 9%",
        },
        {
          id: 10,
          name: "< 10%",
        },
        {
          id: 11,
          name: "< 11%",
        },
        {
          id: 12,
          name: "< 12%",
        },
        {
          id: 13,
          name: "< 14%",
        },
        {
          id: 14,
          name: "< 14%",
        },
        {
          id: 15,
          name: "< 15%",
        },
      ]}
    />

    <TextInput source="slug" alwaysOn />
  </Filter>
);

