export default {
  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  },
  toChoices(object) {
    return Object.keys(object).map(key=> ({
      id: key,
      name: key
    }))
  }
}
