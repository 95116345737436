import React from "react";
import dataProvider from "../providers/dataProvider";
import config from "../config";

const last_30days = new Date(Date.now() - 30 * config.TIME.DAY);
const last_7days = new Date(Date.now() - 7 * config.TIME.DAY);
const last_24h = new Date(Date.now() - config.TIME.DAY);

export default function usePostLikes(payload) {
  const [postLikes, setPostLikes] = React.useState(null);

  React.useEffect(() => {
    dataProvider.getList("post-likes", payload).then((res) => {
      if (res.data) {
        setPostLikes(res.data);
      }
    });
  }, [payload]);

  return postLikes;
}

export function useTotalPostLikes() {
  const [total24h, setTotal24h] = React.useState(null);
  const [total7d, setTotal7d] = React.useState(null);
  const [total30d, setTotal30d] = React.useState(null);
  const [totalLifeTime, setTotalLifeTime] = React.useState(null);

  React.useEffect(() => {
    // last_24h
    dataProvider
      .getList("post-likes", {
        filter: {
          updatedAt: {
            $gt: last_24h,
          },
        },
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: 1, perPage: 10 },
      })
      .then((res) => {
        setTotal24h(res.total);
      });

    // last_7days
    dataProvider
      .getList("post-likes", {
        filter: {
          updatedAt: {
            $gt: last_7days,
          },
        },
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: 1, perPage: 10 },
      })
      .then((res) => {
        setTotal7d(res.total);
      });

    // last_30days
    dataProvider
      .getList("post-likes", {
        filter: {
          updatedAt: {
            $gt: last_30days,
          },
        },
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: 1, perPage: 10 },
      })
      .then((res) => {
        setTotal30d(res.total);
      });

    // Life time
    dataProvider
      .getList("post-likes", {
        filter: {},
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: 1, perPage: 10 },
      })
      .then((res) => {
        setTotalLifeTime(res.total);
      });
  }, []);

  return { total24h, total7d, total30d, totalLifeTime };
}
