import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  FunctionField,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { useQueryWithStore } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Pagination from "./common/Pagination";
import React from "react";
import dataProvider from "../providers/dataProvider";
import { PostPanel } from "./Post";
import config from "../config";
import usePostLikes from "../hooks/usePostLikes";
import { useTotalPostLikes } from "../hooks/usePostLikes";
import { useTotalPostViews } from "../hooks/usePostViews";

const usePost = (id) => {
  const [post, setPost] = React.useState(null);
  React.useEffect(() => {
    dataProvider.getOne("posts", { id }).then((res) => {
      setPost(res.data);
    });
  }, [id]);

  return post;
};

export const PostStatisticPostPanel = (item) => {
  const postStatistic = item.record;
  const post = usePost(postStatistic.post);

  if (post) {
    return (
      <div>
        <PostPanel record={post} />
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

const PostLikeCounting = () => {
  const TPL = useTotalPostLikes();
  const TPV = useTotalPostViews();

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData(
      "Liked count",
      TPL.total24h?.toLocaleString(),
      TPL.total7d?.toLocaleString(),
      TPL.total30d?.toLocaleString(),
      TPL.totalLifeTime?.toLocaleString()
    ),
    createData(
      "Viewed count",
      TPV.total24h?.toLocaleString(),
      TPV.total7d?.toLocaleString(),
      TPV.total30d?.toLocaleString(),
      TPV.totalLifeTime?.toLocaleString()
    ),
    createData(
      "Liked / Viewed (%)",
      Math.round((1000 * TPL.total24h) / TPV.total24h) / 10 + "%",
      Math.round((1000 * TPL.total7d) / TPV.total7d) / 10 + "%",
      Math.round((1000 * TPL.total30d) / TPV.total30d) / 10 + "%",
      Math.round((1000 * TPL.totalLifeTime) / TPV.totalLifeTime) / 10 + "%"
    ),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Statistic by time">
        <TableHead>
          <TableRow>
            <TableCell>Attribute/Statistic</TableCell>
            <TableCell align="right">Last 24h</TableCell>
            <TableCell align="right">Last 7 days</TableCell>
            <TableCell align="right">Last 30 days</TableCell>
            <TableCell align="right">Life time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PostStatisticList = (props) => {
  const [postLikeCountingPanel, setPostLikeCountingPanel] =
    React.useState(null);

  React.useEffect(() => {
    setPostLikeCountingPanel(<PostLikeCounting />);
  }, []);

  return (
    <>
      {postLikeCountingPanel}

      <h3>Moved statistic table to Post page (List Type: statistic)</h3>
    </>
  );
};

export const PostStatisticCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="password" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const PostStatisticEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const PostStatisticEdit = (props) => (
  <Edit actions={<PostStatisticEditActions />} {...props}>
    <SimpleForm>
      <EmailField source="email" />
      <TextInput source="name" />
      <SelectInput
        source="role"
        choices={[
          { id: "SUPPERADMIN", name: "SUPPERADMIN" },
          { id: "ADMIN", name: "ADMIN" },
          { id: "ASSISTANT", name: "ASSISTANT" },
          { id: "USER", name: "USER" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const PostStatisticFilter = (props) => (
  <Filter {...props}>
    <TextInput label="post" source="post" alwaysOn />
  </Filter>
);
