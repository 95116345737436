// import logo from './logo.svg';
import * as React from "react";
import { Admin, Resource, ListGuesser, EditGuesser } from "react-admin";

import "./App.css";
import "./service/firebase";
import { firebaseLogin } from "./service/firebase";

import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";

import MyLayout from "./layout/MyLayout";
import Dashboard from "./dashboard";

import * as Post from "./components/Post";
import * as User from "./components/User";
import * as PostClick from "./components/PostClick";
import * as PostReport from "./components/PostReport";
import * as Traffic from "./components/Traffic";
import * as Bug from "./components/Bug";
import * as Iap from "./components/Iap";
import * as Notification from "./components/Notification";
import * as Mail from "./components/Mail";
import * as PostStatistic from "./components/PostStatistic";
import { CategoryEdit } from "./components/Category";
import * as Category from "./components/Category";

HTMLElement.prototype.scrollIntoView = function () {};

const List = (props) => {
  return <ListGuesser {...props} bulkActionButtons={false} />;
};

const App = () => {
  const role = authProvider.getRole();

  React.useEffect(() => {
    firebaseLogin();
  }, []);

  switch (role) {
    case "SUPPERADMIN":
      return (
        <Admin
          layout={MyLayout}
          authProvider={authProvider}
          dataProvider={dataProvider}
          dashboard={Dashboard}
        >
          <Resource name="auth" list={List} edit={EditGuesser} />
          <Resource
            name="users"
            list={User.UserList}
            edit={User.UserEdit}
            create={User.UserCreate}
          />
          <Resource
            name="posts"
            list={Post.PostList}
            create={Post.PostCreate}
            edit={Post.PostEdit}
          />
          {/* <Resource name="post-clicks" list={List} edit={EditGuesser} /> */}
          <Resource name="post-clicks" list={PostClick.PostClickList} />
          <Resource name="post-likes" list={List} edit={EditGuesser} />
          <Resource
            name="post-reports"
            list={PostReport.PostReportList}
            edit={PostReport.PostReportEdit}
            create={PostReport.PostReportCreate}
          />
          <Resource name="post-views" list={List} edit={EditGuesser} />
          <Resource
            name="post-statistics"
            list={PostStatistic.PostStatisticList}
          />
          <Resource name="recommends" list={List} edit={EditGuesser} />
          <Resource name="trends" list={List} edit={EditGuesser} />
          <Resource
            name="categories"
            list={Category.CategoryList}
            edit={Category.CategoryEdit}
            create={Category.CategoryCreate}
          />
          <Resource name="iaps" list={Iap.IapList} />
          <Resource name="notifications" list={Notification.NotificationList} />
          <Resource name="mails" list={Mail.MailList} edit={Mail.MailEdit} />

          <Resource name="traffics" list={Traffic.TrafficList} />
          <Resource name="bugs" list={Bug.BugList} edit={EditGuesser} />
        </Admin>
      );
    case "ADMIN":
      return (
        <Admin
          layout={MyLayout}
          authProvider={authProvider}
          dataProvider={dataProvider}
        >
          <Resource name="users" />
          <Resource
            name="categories"
            list={Category.CategoryList}
            edit={Category.CategoryEdit}
          />
          <Resource
            name="posts"
            list={Post.PostList}
            create={Post.PostCreate}
            edit={Post.PostEdit}
          />
          {/* <Resource name="hashtags" list={List} edit={EditGuesser} /> */}
          {/* <Resource name="post-likes" list={List} edit={EditGuesser} /> */}
          <Resource name="post-reports" list={PostReport.PostReportList} />
          <Resource
            name="post-statistics"
            list={PostStatistic.PostStatisticList}
          />
          {/* <Resource name="post-views" list={List} edit={EditGuesser} />
                    <Resource name="recommends" list={List} edit={EditGuesser} />
                    <Resource name="trends" list={List} edit={EditGuesser} />
                    <Resource name="categories" list={List} edit={EditGuesser} /> */}

          {/* <Resource name="traffics" list={Traffic.TrafficList} /> */}
          {/* <Resource name="bugs" list={Bug.BugList} edit={EditGuesser} /> */}
        </Admin>
      );
    case "ASSISTANT":
      return (
        <Admin
          layout={MyLayout}
          authProvider={authProvider}
          dataProvider={dataProvider}
          dashboard={Dashboard}
        >
          <Resource name="categories" list={Category.CategoryList} />
          <Resource name="users" />
          <Resource
            name="posts"
            list={Post.PostList}
            create={Post.PostCreate}
            edit={Post.PostEdit}
          />
          <Resource
            name="post-reports"
            list={PostReport.PostReportList}
            edit={PostReport.PostReportEdit}
            create={PostReport.PostReportCreate}
          />
        </Admin>
      );

    default:
      return (
        <Admin
          layout={MyLayout}
          authProvider={authProvider}
          dataProvider={dataProvider}
          dashboard={Dashboard}
        >
          <Resource name="trends" list={List} edit={EditGuesser} />
        </Admin>
      );
  }
};

export default App;
