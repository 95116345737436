import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import Pagination from "./common/Pagination";

const MailPanel = (item) => {
  const post = item.record;
  return (
    <div>
      <br />
      {post &&
        post.contents &&
        post.contents.map((content) => {
          return (
            <div key={content._id}>
              {content.style}
              <br />
              {content.text}
              <br />
              <br />
            </div>
          );
        })}
    </div>
  );
};

export const MailList = (props) => (
  <List
    {...props}
    filters={<MailFilter />}
    bulkActionButtons={false}
    pagination={<Pagination />}
  >
    <Datagrid>
      <DateField
        source="createdAt"
        showTime
        options={{ hour12: false }}
        style={{ width: 200 }}
      />

      <TextField source="type" />
      <TextField source="opened" />
      <DateField source="opened_at" showTime options={{ hour12: false }} />
      <TextField source="clicked" />
      <TextField source="clicked_at" />
      <TextField source="clicked_types" />

      <TextField source="user._id" />
      <TextField source="user.email" />
      <DateField
        source="user.last_active_at"
        showTime
        options={{ hour12: false }}
      />
      <NumberField source="user.mail_sent_count" />
      <NumberField source="user.mail_opened_count" />
      <NumberField source="user.mail_clicked_count" />
      <BooleanField source="user.mail_is_active_subscriber" />
      <TextField source="user.mail_unsubscribed_reason" />
      <DateField
        source="user.mail_last_sent_at"
        showTime
        options={{ hour12: false }}
      />

      <EditButton></EditButton>
    </Datagrid>
  </List>
);

export const MailCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <SelectInput
        source="source"
        choices={[
          { id: "LOGIN", name: "LOGIN" },
          { id: "SUBSCRIBE", name: "SUBSCRIBE" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const MailEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);
const Aside = ({ record }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Mail details</Typography>
    {record && (
      <Typography variant="body2">Creation date: {record.createdAt}</Typography>
    )}
  </div>
);
export const MailEdit = (props) => (
  <Edit actions={<MailEditActions />} {...props}>
    <SimpleForm>
      <EmailField source="email" />
      <TextInput source="name" />

      <SelectInput
        source="source"
        choices={[
          { id: "LOGIN", name: "LOGIN" },
          { id: "SUBSCRIBE", name: "SUBSCRIBE" },
        ]}
      />
      <TextInput source="unsubscribe_reason" />
      <BooleanInput source="active" />
      <TextInput source="sent_types" />

      <ArrayInput source="sent_types">
        <SimpleFormIterator inline>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="sent_count" />
    </SimpleForm>
  </Edit>
);

export const MailFilter = (props) => (
  <Filter {...props}>
    <TextInput label="user" source="user" alwaysOn />
    <TextInput label="type" source="type" alwaysOn />
    <BooleanInput label="opened" source="opened" alwaysOn />
  </Filter>
);
