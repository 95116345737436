import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  FunctionField,
  NumberInput,
  BooleanField,
  DateField,
  Filter,
  SearchInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import Pagination from "./common/Pagination";

const CategoryPanel = (item) => {
  const post = item.record;
  return (
    <div>
      <br />
      {post &&
        post.contents &&
        post.contents.map((content) => {
          return (
            <div key={content._id}>
              {content.style}
              <br />
              {content.text}
              <br />
              <br />
            </div>
          );
        })}
    </div>
  );
};

export const CategoryList = (props) => (
  <List
    {...props}
    filters={<CategoryFilter />}
    pagination={<Pagination />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <EditButton />
      <TextField source="name" />
      <NumberField source="post_count" />
      <FunctionField
        label="Color"
        style={{
          textAlign: "center",
        }}
        render={(record) => (
          <div style={{ backgroundColor: record.color }}>{record.color}</div>
        )}
      />
      <TextField source="unlock" />
      <FunctionField
        label="Image"
        style={{
          textAlign: "center",
        }}
        render={(record) => (
          <a href={record.image} target="_blank">
            <img height={20} src={record.image} />
          </a>
        )}
      />

      <NumberField source="statistic.recommend_point" label="Recom" />
      <NumberField source="statistic.trend_point" label="Trend" />

      <NumberField source="statistic.liked_24h" label="L 24h" />
      <NumberField source="statistic.viewed_24h" label="V 24h" />
      <FunctionField
        label="Rate 24h"
        source="statistic.rate_like_view_24h"
        render={(record) => <b>{record.statistic?.rate_like_view_24h}%</b>}
      />

      <NumberField source="statistic.liked_7days" label="L 7D" />
      <NumberField source="statistic.viewed_7days" label="V 7D" />
      <FunctionField
        label="Rate 7D"
        source="statistic.rate_like_view_7days"
        render={(record) => <b>{record.statistic?.rate_like_view_7days}%</b>}
      />

      <NumberField source="statistic.liked_30days" label="L 30D" />
      <NumberField source="statistic.viewed_30days" label="V 30D" />
      <FunctionField
        label="Rate 30D"
        source="statistic.rate_like_view_30days"
        render={(record) => <b>{record.statistic?.rate_like_view_30days}%</b>}
      />

      <NumberField source="statistic.liked_lifetime" label="L All" />
      <NumberField source="statistic.viewed_lifetime" label="V All" />
      <FunctionField
        label="Rate All"
        source="statistic.rate_like_view_lifetime"
        render={(record) => <b>{record.statistic?.rate_like_view_lifetime}%</b>}
      />

      <DateField
        source="statistic.updatedAt"
        showTime
        options={{ hour12: false }}
      />
    </Datagrid>
  </List>
);

export const CategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput
        source="unlock"
        choices={[
          { id: "PREMIUM", name: "PREMIUM" },
          { id: "REWARD_ADS", name: "REWARD_ADS" },
          { id: "FREE", name: "FREE" },
        ]}
      />
      <TextInput source="color" />
      <TextInput source="image" />
    </SimpleForm>
  </Create>
);

const CategoryEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);
const Aside = ({ record }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Category details</Typography>
    {record && (
      <Typography variant="body2">Creation date: {record.createdAt}</Typography>
    )}
  </div>
);
export const CategoryEdit = (props) => (
  <Edit actions={<CategoryEditActions />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="post_count" />
      <SelectInput
        source="unlock"
        choices={[
          { id: "PREMIUM", name: "PREMIUM" },
          { id: "REWARD_ADS", name: "REWARD_ADS" },
          { id: "FREE", name: "FREE" },
        ]}
      />
      <TextInput source="color" />
      <TextInput source="image" />
    </SimpleForm>
  </Edit>
);

export const CategoryFilter = (props) => (
  <Filter {...props}>{/* <SearchInput source="features" alwaysOn /> */}</Filter>
);
