import React, { Component } from "react";
import { Pagination } from "react-admin";

export default function MyPagination(props) {
  return (
    <Pagination
      rowsPerPageOptions={[10, 25, 50, 100, 200, 500, 1000]}
      {...props}
    />
  );
}
