// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAO04i9hOZfKwvcPiTCw37LQRcND_EwpAg",
  authDomain: "lifehacks-f3510.firebaseapp.com",
  databaseURL: "https://lifehacks-f3510.firebaseio.com",
  projectId: "lifehacks-f3510",
  storageBucket: "lifehacks-f3510.appspot.com",
  messagingSenderId: "612540481741",
  appId: "1:612540481741:web:f2a8e3d32e94e67fcb2984",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseLogin = () => {
  const fbAuth = getAuth();

  if (!fbAuth.currentUser) {
    signInWithEmailAndPassword(
      fbAuth,
      "uploader@bringhacks.com",
      "BringHacks1349354"
    )
      .then((userCredential) => {
        // Signed in
        const fbUser = userCredential.user;
        console.log("firebase authenticated:", fbUser);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  }
};
