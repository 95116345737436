export default [
  {
    _id: "65102e4d4ba6026c0578d453",
    email: "thao@bringhacks.com",
  },
  {
    _id: "64bfd02b83fa38129c3987d2",
    email: "huong@bringhacks.com",
  },
  {
    _id: "633eb5ce6251873e7e0b36a3",
    email: "thuan@bringhacks.com",
  },
];
